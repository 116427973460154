import React from 'react';

/* import {Link} from 'react-router-dom'; */

class Realestate extends React.Component{

    render(){
        return(
            <div>
                <section>

                   {/*  <!-- Inner Banner --> */}
                    <div className="inner_banner"  style={{  backgroundImage: `url("../assets/images/banners/realestatebanner.jpg")` }}>
                        <div className="container">
                            <h1>Real Estate</h1>
                        </div>
                    </div>
                    {/* <!-- Realestate --> */}
                        <div className="inner_about">
                            <div className="container">
                                <div className="row flex-row-reverse">
                                    <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                                        <h2 className="global_title"><span>Real Estate</span></h2>
                                        <p>Our real estate industry believes in building aesthetical and structurally remarkable projects. We treat everyone with the best in class advice whether you аrе a fіrѕt-tіmе buyer оr соrроrаtе bоdу. Our aim is to build long-term rеlаtіоnѕhірѕ with our customers and help them to take a bеttеr рrореrtу dесіѕіоnѕ. We ѕеrve our сuѕtоmеrѕ with a wide-range of ѕресіаltу and lосаl knowledge in buying, ѕеllіng аnd rеntіng rеѕіdеntіаl аnd соmmеrсіаl spaces. Our team is always ready for the property requisites. Explore our real estate industries which are rіghtlу fit for уоu аnd lеаvе the rеѕt оn uѕ.</p>
                                    </div>
                                    <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                                        <figure><img src="../assets/images/brand/realestate/realestate_i.jpg" alt="Real Estate" /></figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Team Sec --> */}
                        <div className="team_sec">
                            <div className="container">
                                <h2 className="global_title"><span>Brands</span></h2>
                                <div className="row">
                                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                        <div className="team_block">
                                            <div><img className="brand-image" src="../assets/images/brand/realestate/kamalya_bd.jpg" alt=""/></div>
                                            <h3>Kamalya Builders And Developers </h3>
                                            <p>Kamalya Builders And Developers LLP is a Limited Liability Partnership firm incorporated on 03 July 2013. Designated Partners of Kamalya Builders And Developers Llp are Udit Kumar, Vaibhav Sanjay Thakrar, Arnav Kumar.</p>
                                            <a href="https://www.zaubacorp.com/company/KAMALYA-BUILDERS-AND-DEVELOPERS-LLP/AAB-6255" target="_blank" rel="noreferrer" className="more_link">Go to Brand Page <i className="fa fa-long-arrow-right"></i></a>
                                        </div>
                                    </div>
                                    
                                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                        <div className="team_block">
                                            <div><img className="brand-image" src="../assets/images/brand/realestate/indus.jpg" alt="team_img" /></div>
                                            <h3>Indus Techpark Private Limited </h3>
                                            <p>Indus Techpark Private Limited is a Private, incorporated on 03 July 2003. It is classified as a Non-govt company and is registered at the Registrar of Companies. Directors are Udit, Arnav Kumar, Shipra Kumar, Vivek Kumar, Apurv Kumar, Anoop Kumar, Arvind Kumar.</p>
                                            <a href="https://www.zaubacorp.com/company/INDUS-TECHPARK-PRIVATE-LIMITED/U07010KA2003PTC032202" className="more_link">Go to Brand Page <i className="fa fa-long-arrow-right"></i></a>
                                        </div>
                                    </div>
                                    
                                    
                                </div>

                                <div className="row">
                                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                        <div className="team_block">
                                            <div><img className="brand-image" src="../assets/images/brand/realestate/eco_global_infra.jpg" alt=""/></div>
                                            <h3>Eco Global infrastructure </h3>
                                            <p>Eco-infrastructure or environment-friendly infrastructure or green infrastructure is the creation of an infrastructure that has certain essential ingredients such as resilience towards climate change, adaptability & environmental sustainability towards the same. </p>
                                            <a href="/" target="_blank" rel="noreferrer" className="more_link">Go to Brand Page <i className="fa fa-long-arrow-right"></i></a>
                                        </div>
                                    </div>

                                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                        <div className="team_block">
                                            <div><img className="brand-image" src="../assets/images/brand/realestate/eco_developer_global.jpg" alt=""/></div>
                                            <h3>Eco Global Developers and Builders</h3>
                                            <p>We are the fastest-growing company in the online real estate space. Born out of the need to simplify the search for a home, we created a unique property search platform that filled the gaps left by others in the market.</p>
                                            <a href="/" target="_blank" rel="noreferrer" className="more_link">Go to Brand Page <i className="fa fa-long-arrow-right"></i></a>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>


                    </section>
            </div>
            )
        }
    }
    export default Realestate