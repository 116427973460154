import React from 'react';

/* import {Link} from 'react-router-dom'; */

class Textile extends React.Component{

    render(){
        return(
            <div>
                <section>

                   {/*  <!-- Inner Banner --> */}
                    <div className="inner_banner" style={{  backgroundImage: `url("../assets/images/banners/textilebanner.jpg")` }}>
                        <div className="container">
                            <h1>Textile</h1>
                        </div>
                    </div>
                    {/* <!-- Textile --> */}
                        <div className="inner_about">
                            <div className="container">
                                <div className="row flex-row-reverse">
                                    <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                                        <h2 className="global_title"><span>Textile</span></h2>
                                        <p>"Textile sector" - one of the oldest industries in the Indian economy. Our textile industry is primarily concerned with the design, production, and distribution of cloth and clothing. Our Textiles are one of the leading sellers in India. We design, manufacture, and distribute high-quality products. While keeping pace with changes in fashion and technology, our textile industry has always remained faithful to its core value of high production, quality standards, and customer satisfaction. With the use of advanced technology and modern equipment, we aim to deliver the best clothes to our customers. </p>
                                    </div>
                                    <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                                        <figure><img src="../assets/images/brand/textile/textile.jpg" alt="Textile" /></figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Team Sec --> */}
                        <div className="team_sec">
                            <div className="container">
                                <h2 className="global_title"><span>Brands</span></h2>
                                <div className="row">
                                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                        <div className="team_block">
                                            <div><img className="brand-image" src="../assets/images/brand/textile/indusintex.jpg" alt=""/></div>
                                            <h3>Indus Intex Private Limited</h3>
                                            <p>Renowned for its ability to create products that combine style and luxury with supreme quality standards, Indus Intex has evolved into one of India’s top design-to-finished garment companies in India.</p>
                                            <a href="https://www.indus-tex.com/" target="_blank" rel="noreferrer" className="more_link">Go to Brand Page <i className="fa fa-long-arrow-right"></i></a>
                                        </div>
                                    </div>
                                    
                                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                        <div className="team_block">
                                            <div><img className="brand-image" src="../assets/images/brand/textile/panaya.jpg" alt="team_img" /></div>
                                            <h3>Kamalya Textiles </h3>
                                            <p>Kamalya Textiles has made a name for itself in the list of top suppliers in India, and it is listed in Trade India's list of verified sellers offering supreme quality products and services.</p>
                                            <a href="https://www.indus-tex.com/" target="_blank" rel="noreferrer" className="more_link">Go to Brand Page <i className="fa fa-long-arrow-right"></i></a>
                                        </div>
                                    </div>
                                    
                                    
                                </div>

                                <div className="row">
                                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                        <div className="team_block">
                                            <div><img className="brand-image" src="../assets/images/brand/IMY.jpg" alt="Indian Textiles"/></div>
                                            <h3>Indian Textiles</h3>
                                            <p>Indian Textiles is known as the Mother company of textiles.  </p>
                                            <a href="#" target="_blank" rel="noreferrer" className="more_link">Go to Brand Page <i className="fa fa-long-arrow-right"></i></a>
                                        </div>
                                    </div>

                                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                        <div className="team_block">
                                            <div><img className="brand-image" src="../assets/images/brand/imy_.jpeg" height="390" alt="IMY"/></div>
                                            <h3>IMY</h3>
                                            <p>IMY is a leading menswear, with over 500+ designs across its collection of casual and semi-formal wear. Quality product at affordable price is IMY's belief making fashion feasible for all. </p>
                                            <a href="https://www.instagram.com/imyfashion/" target="_blank" rel="noreferrer" className="more_link">Go to Brand Page <i className="fa fa-long-arrow-right"></i></a>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>


                    </section>
            </div>
            )
        }
    }
    export default Textile