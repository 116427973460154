import React from 'react';

/* import {Link} from 'react-router-dom'; */
 
class Vivek extends React.Component{
    render(){
        return(
            <div>
                <section>

                   {/*  <!-- Inner Banner --> */}
                    <div className="inner_banner">
                        <div className="container">
                            {/* <h1>ARVIND KUMAR</h1> */}
                        </div>
                    </div>
                    {/* <!-- About --> */}
                        <div className="inner_about">
                            <div className="container">
                                <div className="row flex-row-reverse">
                                    <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                                        <h2 className="global_title"><span>MR. VIVEK KUMAR</span></h2>
                                        <h4>MANAGING DIRECTOR</h4>
                                        <p></p>
                                        <p>Mr. Vivek Kumar is born and brought up in the city of Lord Shiva - the oldest city in the world, Varanasi. He is an astute businessman and philanthropist with the mind of a free spirit creative thinker. Mr. Vivek is the Managing Director of Clarks Brij Hotels Private Limited, Pride Hospitality Limited, Brijrama Hospitality Private Limited, India Textiles Company (Kolkata) Private Limited, and Indus Intex Private Limited, notably. Mr. Vivek hails from a family engaged in retail, textile, and hospitality for over three generations. His GrandFather, Sri Brijpal Das Ji, established one of the first chain of five-star hotels, Clarks Group of Hotels, in India.  He also established Indian Textiles Company Private Limited in 1935. His interests have been in the fields of hospitality and textile. </p>
                                        <p>Surrounded by brothers working successfully in the hospitality industry, it was natural that Mr. Vivek established himself in this field by founding Clarks Exotica Convention Resort and Spa. Within two years of its inception, under Mr. Vivek’s leadership, Clarks Exotica became profitable. His expertise across multiple domains has helped grow Clarks Exotica Convention Resort and Spa from 20 to 123 keys in less than a decade and open one of the largest convention spaces (over 2 lakh feet) in Asia. This has resulted in Clarks Exotica establishing itself as one of the premium five-star resorts in the country and win multiple awards for Best Practices and Quality of Service. Mr. Vivek truly supports the entrepreneurial spirit by promoting new ideas and talent. </p>
                                        <p>Mr. Vivek draws his strength and inspiration from his family. He strongly believes in contributing to society and is involved in several philanthropic initiatives, especially in education and healthcare (established Heart Care Foundation). His hobbies include listening to instrumental music and reading nonfiction books on Indian Culture and Medicine. He is also fond of collecting stamps.</p>
                                        <br/>
                                        <div className="team_social">
                                                {/* <a href="/"><i className="fa fa-twitter"></i></a>
                                                <a href="/"><i className="fa fa-facebook"></i></a> */}
                                                <a target="_blank" href="https://www.linkedin.com/in/vivek-kumar-9a415215/"><i className="fa fa-linkedin-square"></i></a>
                                        </div>
                                    </div>
                                    <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                                        <figure><img src="/assets/images/teams/2.jpg" alt="MR. VIVEK KUMAR" /></figure>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>
            </div>
            )
        }
    }
    export default Vivek