import React from 'react';
import axios from "axios";

/* import {Link} from 'react-router-dom'; */

class Contact_form extends React.Component{
    
    constructor(props) {
        super(props);
        this.state = {
          name: '',
          email: '',
          phone:'',
          subject:'',
          message: ''
        };
        this.messagealert='';

      }

      handleChange(event) {
        const field = event.target.id;
        if (field === "name") {
          this.setState({ name: event.target.value });
        }  else if (field === "email") {
            this.setState({ email: event.target.value });
        }else if (field === "phone") {
            const re = /^[0-9\b]+$/; //rules
            if (event.target.value === "" || re.test(event.target.value)) {
                this.setState({ phone: event.target.value });
            }
           // this.setState({ phone: event.target.value });
        } else if (field === "subject") {
            this.setState({ subject: event.target.value });
        }  else if (field === "message") {
          this.setState({ message: event.target.value });
        }
      }

      handleSubmit(event) {
          var self = this;
        event.preventDefault();  
        this.setState({ status: "Sending" });  
        /* var o =JSON.stringify(this.state); */
        var o =this.state;
        
        var post_uri =window.API_URL+'contacts';
        axios.post(post_uri, o).then(function (response) {

                if(response.data.id > 0){

                    //alert(response.data.message);
                    self.setState({name: "", email: "", phone:"", subject:"", message: ""});
                    self.messagealert='Thanks you for contact us!';
                }else{
                    alert(response.data.message);
                    
                }
            })
            .catch(function (error) {
            alert(error); 
            });
      }

      
      
    render(){
        return(
            <div>
             
                    <form name="contactus"  id="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST">
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                            <div className="form_block">
                                                <input type="text" id="name" className="form-control" placeholder="Full Name"  value={this.state.name}
                onChange={this.handleChange.bind(this)}  required/>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                            <div className="form_block">
                                                <input type="email" id="email" className="form-control" placeholder="Email Address"  value={this.state.email}
                onChange={this.handleChange.bind(this)}  required/>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                            <div className="form_block">
                                                <input type="text" id="phone" className="form-control" placeholder="Phone Number"  value={this.state.phone}
                onChange={this.handleChange.bind(this)}  minLength="10" maxLength="11" required/>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                            <div className="form_block">
                                                <input type="text" id="subject" className="form-control" placeholder="Subject"  value={this.state.subject}
                onChange={this.handleChange.bind(this)} required />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form_block">
                                                <textarea  id="message" className="form-control" placeholder="Your Message...."  value={this.state.message}
                onChange={this.handleChange.bind(this)} required></textarea>
                                            </div>
                                        </div>
                                        <div className="col-12 text-right">
                                        {this.messagealert}

                                            <button className="btns" type="submit">Send Message</button>
                                        </div>
                                    </div>
                                </form>
                           
                            
                        

               

            </div>
            )
        };
    }

    

    
    export default Contact_form