import React from 'react';

/* import {Link} from 'react-router-dom'; */

class Arvind extends React.Component{
    render(){
        return(
            <div>
                <section>

                   {/*  <!-- Inner Banner --> */}
                    <div className="inner_banner">
                        <div className="container">
                            {/* <h1>ARVIND KUMAR</h1> */}
                        </div>
                    </div>
                    {/* <!-- About --> */}
                        <div className="inner_about">
                            <div className="container">
                                <div className="row flex-row-reverse">
                                    <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                                        <h2 className="global_title"><span>Sri ARVIND KUMAR</span></h2>
                                        <h4>CHAIRMAN</h4>
                                        <p></p>
                                        <p>Sri Arvind Kumar was born and brought up in Varanasi, successfully started one of India’s first premium five-star chain of hotels, the Clarks Group of Hotels. Along with this, he established several successful businesses, mainly in textiles and trade firms. Born in a business family, there were high expectations to further strengthen the group and fulfill his ambitions. He has always had a passion for hotels. He has been involved in managing the end-to-end of the hotel business from a very early age. He noticed that his family lacked the presence in the luxury/boutique sector. He took the risk by venturing into unknown territory. He established a new line of hotels under the brand name Brij Hotels, the first one being a Palace converted to a 32 room Boutique Hotel on the Ghats of Varanasi, Brijrama Palace. Simultaneously focused on the textiles business. </p>
                                        <p>Shri Arvind Kumar, has an innate passion for increasing tourism to Varanasi and doing good for its people. He established the Anathalaya foundation (for orphans in Varanasi) and the Aggarwal Samaj University for underprivileged females. Sri AVK also monitors and guides the working of two foundations. Sri Das Foundation (education for the underprivileged) and Brijrama Foundation providing medical and financial needs for the underprivileged - especially women. </p>
                                        <p>Sri AVKs hobby is bird watching and reading books to his GrandChildren. </p>
                                    </div>
                                    <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                                        <figure><img src="/assets/images/teams/1.jpg" alt="Sri Arvind Kumar" /></figure>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>
            </div>
            )
        }
    }
    export default Arvind