import React from 'react';

/* import {Link} from 'react-router-dom'; */

class Balaji extends React.Component{
    render(){
        return(
            <div>
                <section>

                   {/*  <!-- Inner Banner --> */}
                    <div className="inner_banner">
                        <div className="container">
                            {/* <h1>ARVIND KUMAR</h1> */}
                        </div>
                    </div>
                    {/* <!-- About --> */}
                        <div className="inner_about">
                            <div className="container">
                                <div className="row flex-row-reverse">
                                    <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                                        <h2 className="global_title"><span>MR. M BALAJI</span></h2>
                                        <h4>CEO</h4>
                                        <p></p>
                                        <p>M Balaji is the Chief Executive Officer at Clarks Exotica Convention Resort and Spa, Bangalore. With nearly two decades of experience in operations, events, business development, sales, management, and marketing leadership roles, Balaji brings strong business experience and in-depth knowledge of the Indian Hospitality industry. He is instrumental in establishing Clarks Exotica Convention Resort & Spa in Bengaluru. Balaji started working at the age of 9 to support his education. At the age of 19, he bagged a job at the Tourism Department, Government of Karnataka. A few years later, he moved to explore human resource skills as HR Manager at Indus Textiles. He joined the Clarks Exotica family during its inception in 2007.</p>
                                        <p>Conscious about the challenges ahead and being aware that he was not from a hospitality background, Balaji learned as much as possible about the industry. Balaji has been involved with every aspect of Clarks Exotica. He implemented some of the key policies and changes that have resulted in the brand becoming one of the premium five-star hotels in the country. He is actively involved in strategizing, conceptualizing, and business development for the hotel. He has also ensured to develop key people in his team and ensured that they have a successful career plan. Apart from being a passionate hotelier, he is a keen travel enthusiast. He travels a lot and vacations in Europe, particularly Paris, exploring the cuisine, architecture, and art. He is also fond of music and poetry and keeps himself fit by practicing martial arts. Bruce Lee — a famous martial artist </p>
                                        <br/>
                                        <div className="team_social">
                                                {/* <a href="/"><i className="fa fa-twitter"></i></a>
                                                <a href="/"><i className="fa fa-facebook"></i></a> */}
                                                <a target="_blank" href="https://www.linkedin.com/in/balaji-m-062a891b4/"><i className="fa fa-linkedin-square"></i></a>
                                        </div>
                                    </div>
                                    <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                                        <figure><img src="/assets/images/teams/5.jpg" alt="MR. M BALAJI" /></figure>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>
            </div>
            )
        }
    }
    export default Balaji