/*import React,{Component} from 'react'; */
import React from "react";
import { NavLink } from "react-router-dom";
class Footer extends React.Component {
  render() {
    return (
      <div>
        {/* <!-- Footer --> */}
        <footer>
          <div className="footer_sec">
            <div className="container">
              <div className="row">
                <div className="col-xl-5 col-lg-4 col-md-12 col-sm-12">
                  <figure>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/footer_logo.svg"
                      }
                      alt=""
                    />
                  </figure>
                  <p>
                    Kamalya Group is recognized for its unique flavor in
                    textile, hospitality, fitness, and real estate, food and
                    beverages adapting to modern technology, in accordance to
                    growing needs and global environments.
                  </p>
                  <p>
                    We are a team of hard working people focusing on the day to
                    day activities by spending their whole time for the
                    furtherance of the organization.
                  </p>
                  {/* <ul className="social_links">
										<li><a href="/"><i className="fa fa-twitter"></i></a></li>
										<li><a href="/"><i className="fa fa-facebook-f"></i></a></li>
										<li><a href="/"><i className="fa fa-instagram"></i></a></li>
									</ul> */}
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                  <h3>Our Links</h3>
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                      <ul className="footer_links">
                        <li>
                          <NavLink to="/">Home</NavLink>
                        </li>
                        {/* <li><NavLink  to='/about'>About Us</NavLink></li> */}

                        <li>
                          <NavLink to="/careers">Careers</NavLink>
                        </li>
                        <li>
                          <NavLink to="/blogs">Blogs</NavLink>
                        </li>
                        <li>
                          <NavLink to="/awards">Awards</NavLink>
                        </li>
                        <li>
                          <NavLink to="/contact">Contact Us</NavLink>
                        </li>
                      </ul>
                    </div>
                    {/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
											<ul className="footer_links">
												<li><NavLink  to='/awards'>Awards</NavLink></li>
												<li><NavLink  to='/contact'>Contact Us</NavLink></li>
											</ul>
										</div> */}
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                  <h3>Our Industries</h3>
                  <ul className="footer_links">
                    <li>
                      <NavLink to="/industries/textile">Textile</NavLink>
                    </li>
                    <li>
                      <NavLink to="/industries/hospitality">
                        Hospitality
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/industries/wellness">Wellness</NavLink>
                    </li>
                    <li>
                      <NavLink to="/industries/food-and-beverages">
                        Food and Beverages
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/industries/real-estate">
                        Real Estate
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="copyright">
            <div className="container">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <p>KAMALYA GROUP © 2021 All Right Reserved</p>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 text-right">
                  {/* <p><a href="/terms-services">Terms of Services</a> <span>|</span>
									 <a href="/privacy-policy">Privacy Policy</a></p> */}
                  <p>
                    Designed & Developed by{" "}
                    <a href="https://digikit.in/" target="_blank">
                      DIGIKIT
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}
export default Footer;
