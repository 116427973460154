import React from 'react';
import axios from "axios";
import {useState,useEffect} from 'react';
//import {useEffect} from 'react';

import {useParams,NavLink} from "react-router-dom";


/* import {Link} from 'react-router-dom'; */

//class Blogsview extends React.Component{
    function Blogsview(props) {
        //const blogdata={};
        const [blogdata, setBlogdata] = useState({});
        const [recentblog, setRecentblog] = useState([]);

          let { blogId } = useParams();
        useEffect(()=>{
            
            console.log('test'); 
            //setBlogdata({title: "", image: "", description:"", subject:"", message: ""});
            let mounted = true;
            //this.setState({ status: "Sending" });  
             let post_uri = 'http://neelamsoft.com/kamalya/api/web/blogs/'+blogId;
             axios.get(post_uri).then(function (response) {
                if(response.data){
                    if(mounted){
                        setBlogdata(response.data);
                    }
                }else{
                    alert(response.data.message);
                }
            })
            .catch(function (error) {
                alert(error); 
            });

            let post_urii = window.API_URL+'blogs/';
            axios.get(post_urii).then(function (response) {
              
               if(response.data){
                   if(mounted){
                    let sortd = response.data.sort((a, b) => new Date(b.publish_date) - new Date(a.publish_date))
                    sortd = sortd.filter((d)=>d.id !=blogId);
                    sortd = sortd.filter((d,i)=>i<5);
                    setRecentblog(sortd);
                   }
                       
               }else{
                   alert(response.data.message);
                   
               }
           })
           .catch(function (error) {
               alert(error); 
           });

            return () => mounted = false;
        },[props.match.params.blogId])
       
        return(
            
                <div>
                    <div className="inner_banner"  style={{  backgroundImage: `url("../assets/images/banners/bolgbanner.jpg")` }}>
                    <div className="container">
                        <h1>Blogs</h1>
                    </div>
                </div>

                 {
                        (!blogdata.title) &&
                        <div className="container">
                                    <div className="row">
                                       <p>Loading...</p>  
                        </div>
                        </div>
                    },

               {
                   (blogdata.title) &&
              
                 <section>
                <div className="blog_detail_sec">
                    <div className="container">
                    

                    <div className="row ">
                        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                            <h2 className="global_title"><span>{blogdata.title}</span></h2>
                            <p><small>Publish : {blogdata.publish_date} | Created By : {blogdata.created_by} </small></p> 
                            <figure><img src={window.API_BLOG_IMG_URL+'/'+blogdata.image} alt={blogdata.title} /></figure>
                            
                            <div dangerouslySetInnerHTML={{ __html: blogdata.details }} />
                           
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                            
                        <div className="blog_detail_left_sec">
                            <div className="container">
                            <h4 className="blog_recet_title"><span>Recent Posts</span></h4>
                                <ul >                                   
                                    {recentblog.map(item => <li key={item.id}><NavLink  to={"/blogsview/"+item.id} >{item.title}</NavLink></li>)}                                    
                                </ul>
                                    
                            </div>
                        </div>

                        </div>
                    </div>
                            
                    </div>
                </div> 
                
                </section>
            }

              </div>
            )
        }
    
    export default Blogsview