/*import React,{Component} from 'react'; */
import React from 'react'; 
import Contactfrom from "../components/Contact_form"; 

class Home extends React.Component{

	componentDidMount () {
		const script = document.createElement("script");
		//script.src = "../../public/assets/js/testimonials.js";
		script.src = "/assets/js/testimonials.js";
		script.async = true;
	
		document.body.appendChild(script);
	}

    render(){
        return(
            <div>
                
{/* <!-- Section --> */}

	<section>

	{/* <!-- Hero -->  hero_sec*/}
	<div className="">
		<img src="assets/images/Kamalya.gif" alt="Kamalya" />
		<div className="hero_caption">
			<div className="container">
				{/* <h2>Suspendisse efficitur augue quis.</h2>
				<p>Ut ipsum purus, egestas ut mauris ut, consectetur vestibulum mi. Maecenas ornare id.</p>
				
				<a href="/" className="btns white bordered">Learn More</a> */}
			</div>
		</div>
	</div>

	{/* <!-- About --> */}
	<div className="about_sec">
		<div className="container">
			<div className="row">
				<div className="col-xl-7 col-lg-7 col-md-7 col-sm-12">
					<h2 className="global_title"><span>About Us</span></h2>
					<p>	“Customer is the King”, keeping that at the center of all its business, Kamalya Group, founded by Mr. Vivek Kumar – Chairman & MD has been into business for over 5 decades. Kamalya Group is recognized across the nation for its unique flavor in textile, hospitality, fitness,  real estate and food & beverages adapting each business based on customers, market needs, newest technology, progressive thinking all keeping in accordance to growing needs and global environments.</p>
					<p>Kamalya Group has businesses across Textiles, Hospitality, Wellness, Food & Beverages and Real Estate. </p>					
				</div>
				<div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 align-self-center">
					<div className="video_info">
						<video class="w-100" autoplay="true" controls src="assets/images/logo_intro1.mp4" type="video/mp4">
							Your browser does not support the video tag.
						</video>
						{/* poster="https://www.example.com/poster.png" */}
{/* 
						<ReactVideo
                            src="assets/images/logo_intro1.mp4"                            
                            primaryColor="red"
                         /> */}
					</div>
					
				</div>
			</div>
		</div>
	</div>

	{/* <!-- Services --> */}
	<div className="service_sec">
		<div class="container-fluid">
			<div className="row justify-content-center">
				<div className="col-xl-2 col-lg-2 col-md-6 col-sm-6">
					<a href='/industries/textile' >
					<div className="service">
						<figure><img src="assets/images/srvc1.svg" alt="" /></figure>
						<h3>Textile</h3>
						<p>You can have anything in life, of you dress for it. Comfort, style & trends at affordable price</p>
					</div>
					</a>
				</div>
				<div className="col-xl-2 col-lg-2 col-md-6 col-sm-6">
				<a href='/industries/hospitality' >
					<div className="service">
						<figure><img src="assets/images/srvc2.svg" alt="" /></figure>
						<h3>Hospitality</h3>
						<p>We are passionate to create one of the best experiences for our guests.</p>
					</div>
					</a>
				</div>
				<div className="col-xl-2 col-lg-2 col-md-6 col-sm-6">
				<a href='/industries/wellness' >
					<div className="service">
						<figure><img src="assets/images/srvc3.svg" alt="" /></figure>
						<h3>Wellness</h3>
						<p>Heath is a progressive way to life. Switch your health goals with us</p>
					</div>
					</a>
				</div>
				<div className="col-xl-2 col-lg-2 col-md-6 col-sm-6">
				<a href='/industries/real-estate' >
					<div className="service">
						<figure><img src="assets/images/srvc4.svg" alt="" /></figure>
						<h3>real estate</h3>
						<p>Own your own plot of stories. We enable you create your dream homes </p>
					</div>
					</a>
				</div>

				<div className="col-xl-2 col-lg-2 col-md-6 col-sm-6">
				<a href='/industries/food-and-beverages' >
					<div className="service">
						<figure><img src="assets/images/food.png" alt="" /></figure>
						<h3>Foods and Beverages</h3>
						<p>Feel the excellence taste in every bite, and in the last drop of drink. </p>
					</div>
					</a>
				</div>

			</div>
		</div>		
	</div>

	{/* <!-- Brands --> */}
	<div className="brand_sec">
		<div className="container">
			<h2 className="global_title text-center">Our Brands</h2>
			<div className="row justify-content-center">
				{/* <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6 align-self-center p-3">
					<figure><img src="assets/images/brandlogo/1589.png" alt="" /></figure>
				</div> */}
				<div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6 align-self-center p-3">
					<figure><img src="assets/images/brandlogo/brij.jpg" alt=""/></figure>
				</div>
				<div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6 align-self-center p-3">
					<figure><img src="assets/images/brandlogo/clark-exotica.jpg" alt=""/></figure>
				</div>
				<div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6 align-self-center p-3">
					<figure><img src="assets/images/brandlogo/dolci.jpg" alt="" /></figure>
				</div>
				<div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6 align-self-center p-3">
					<figure><img src="assets/images/brandlogo/imy.png" alt="" /></figure>
				</div>
				<div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6 align-self-center p-3">
					<figure><img src="assets/images/brandlogo/indus.png" alt="" /></figure>
				</div>
				<div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6 align-self-center p-3">
					<figure><img src="assets/images/brandlogo/panaya.png" alt="" /></figure>
				</div>
				<div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6 align-self-center p-3">
					<figure><img src="assets/images/brandlogo/switch.jpg" alt="" /></figure>
				</div>
			</div>
		</div>
	</div>

{/* 	<!-- Counter --> */}
	<div className="counter_sec">
		<div className="container">
			<div className="row">
				<div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
					<div className="count_block">
						<figure><img src="assets/images/count1.svg" alt="" /></figure>
						<div className="r_dtl">
							<strong><span className="counter">150</span>+</strong>
							<p>Project Done</p>
						</div>
					</div>
				</div>
				<div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
					<div className="count_block">
						<figure><img src="assets/images/count2.svg" alt="" /></figure>
						<div className="r_dtl">
							<strong><span className="counter">25</span>+</strong>
							<p>Years Experience</p>
						</div>
					</div>
				</div>
				<div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
					<div className="count_block">
						<figure><img src="assets/images/count3.svg" alt="" /></figure>
						<div className="r_dtl">
							<strong><span className="counter">50</span>+</strong>
							<p>Award Won</p>
						</div>
					</div>
				</div>
				<div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
					<div className="count_block">
						<figure><img src="assets/images/count4.svg" alt="" /></figure>
						<div className="r_dtl">
							<strong><span className="counter">1000</span>+</strong>
							<p>Happy Customer</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	{/* <!-- Form --> */}
	<div className="form_sec">
		<div className="container">
			<h2 className="global_title text-center">Contact Us</h2>
			<Contactfrom></Contactfrom>
		</div>
	</div>

	{/* <!-- Testimonial --> */}
	{/* <div className="testimonial_sec">
		<div className="container">
			<h2 className="global_title text-right"><span>Testimonials</span></h2>
			<div className="testi_slider">
				<div className="owl-carousel testimonial_slider">
					<div className="item">
						<figure><img src="assets/images/testi.png" alt="" /></figure>
						<p><sup className="quoteicn"><img src="assets/images/quote_left.svg" alt="" /></sup> It's fantastic experience right from the beginning of booking to our check out. We enjoyed a lot. your Hospitality is really too good. <sub className="quoteicn"><img src="assets/images/quote_right.svg" alt=""/></sub></p>
						<div className="author">
							<strong>Chandru Singh J</strong>
							Regional Sales Manager, FMC
						</div>
					</div>
					<div className="item">
						<figure><img src="assets/images/testi.png" alt="" /></figure>
						<p><sup className="quoteicn"><img src="assets/images/quote_left.svg" alt="" /></sup> All the arrangements were superb and flawless from Clarks Exotica side. Special thanks to team for outstanding arrangements. Appreciate all youe efforts. Good job <sub className="quoteicn"><img src="assets/images/quote_right.svg" alt=""/></sub></p>
						<div className="author">
							<strong>Vinayak Katwa</strong>
							Accenture, India Delivery Center,Bangalore
						</div>
					</div>
					<div className="item">
						<figure><img src="assets/images/testi.png" alt="" /></figure>
						<p><sup className="quoteicn"><img src="assets/images/quote_left.svg" alt="" /></sup> Thanking you so much for hosting our team outingevent at your resort premises. Overall it went very well, We all team members are extremly happy with the food ,Amenities as well as the hospitality of the team. <sub className="quoteicn"><img src="assets/images/quote_right.svg" alt=""/></sub></p>
						<div className="author">
							<strong>Pavan Kumar M</strong>
							AGM, Partner BU Labs Schneider Electric India Pvt Ltd
						</div>
					</div>
					<div className="item">
						<figure><img src="assets/images/testi.png" alt="" /></figure>
						<p><sup className="quoteicn"><img src="assets/images/quote_left.svg" alt="" /></sup> Rooms are very good, Excellent service, food and best-in class. We highly appreciate your team. We wiil refer to other group companies also if we have any booking in future <sub className="quoteicn"><img src="assets/images/quote_right.svg" alt=""/></sub></p>
						<div className="author">
							<strong>Mr. Pejawar</strong>
							NTT DATA FA Insurance Systems-NDFS
						</div>
					</div>
				</div>
			</div>
			</div>
		</div> */}

	</section>

            </div>

        )
    }

}
export default Home;
