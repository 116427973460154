import React from 'react';
import axios from "axios";

/* import {Link} from 'react-router-dom'; */

class Careers extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
          name: '',
          email: '',
          phone:'',
          message: '',
          uploadfile:null,
          uploadfilneame:''
        }
      }

      handleChange(event) {
        const field = event.target.id;
        if (field === "name") {
          this.setState({ name: event.target.value });
        }  else if (field === "email") {
            this.setState({ email: event.target.value });
        }else if (field === "phone") {
            const re = /^[0-9\b]+$/; //rules
            if (event.target.value === "" || re.test(event.target.value)) {
                this.setState({ phone: event.target.value });
            }

           // this.setState({ phone: event.target.value });
        }  else if (field === "message") {
          this.setState({ message: event.target.value });
        }
        else if (field === "uploadfile") {
            this.setState({ uploadfilneame: event.target.value });
            this.setState({ uploadfile: event.target.files[0] });

          }
      }

      handleSubmit(event) {
        //  var self = this;
        event.preventDefault();  
        this.setState({ status: "Sending" });  
        //var o =JSON.stringify(this.state);

        let formData = new FormData();
        formData.append('imagefile1', this.state.uploadfile);
        formData.append('data', JSON.stringify(this.state));
        
        var post_uri =window.API_URL+'careers.php';
        axios.post(post_uri, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(function (response) {
            if(response.data.error === 0 || response.data.error === '0'){
                alert('message');
            }            
        })
        .catch(function (error) {
            alert(error);
        });


        /*axios.post(post_uri, o).then(function (response) {
                if(response.data.error === 0 || response.data.error === '0'){

                    alert(response.data.message);
                    self.setState({name: "", email: "", phone:"", message: ""});
                }else{
                    alert(response.data.message);
                    
                }
            })
            .catch(function (error) {
            alert(error); 
            });*/
       
      }

    render(){
        return(
            <div>
                    {/*  <!-- Section --> */}
                <section>

                    {/*  <!-- Inner Banner --> */}
                    <div className="inner_banner info_contact_banner" style={{  backgroundImage: `url("assets/images/banners/careersbanner.jpg")` }}>
                        <div className="container">
                            <h1>Careers</h1>
                        </div>
                    </div>

                    {/* <!-- Team Sec --> */} 
                    <div className="inner_about">
                        <div className="container">
                           
                            
                            <div className="row">
                                <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                                    <h2 className="global_title">Career Development</h2>
                                    <p>Create a roadmap for your career. Be a part of our team now!</p>
                                    <p> &nbsp;</p>
                                    <form name="careers"  id="careers-form" onSubmit={this.handleSubmit.bind(this)} method="POST" encType="multipart/form-data">
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                            <div className="form_block">
                                            <input type="text" id="name" className="form-control" placeholder="Full Name"  value={this.state.name}
                onChange={this.handleChange.bind(this)}  required/>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                            <div className="form_block">
                                            <input type="email" id="email" className="form-control" placeholder="Email Address"  value={this.state.email}
                onChange={this.handleChange.bind(this)}  required/>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                            <div className="form_block">
                                            <input type="tel" id="phone" className="form-control" placeholder="Phone Number"  value={this.state.phone}
                onChange={this.handleChange.bind(this)}   minLength="10" maxLength="11"  required/>
                                            </div> 
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                            <div className="form_block">
                                            <div className="custom-file">
  <input type="file" className="custom-file-input" id="uploadfile" name="product_image_1" onChange={this.handleChange.bind(this)}/>
  <label className="custom-file-label" htmlFor="uploadfile" data-browse="Browse File">{this.state.uploadfilneame!==''?this.state.uploadfilneame:'Attached Your Resume...'}</label>
</div>
                                               {/*  <label htmlFor="upload-photo" className="attachedresume"> <i className="fa fa-upload" aria-hidden="true"></i> Attached Your Resume...</label>
                                                <input type="file" name="photo" id="upload-photo"  /> */}

                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form_block">
                                            <textarea  id="message" className="form-control" placeholder="Your Message...."  value={this.state.message}
                onChange={this.handleChange.bind(this)} required></textarea>
                                            </div>
                                        </div>
                                        <div className="col-12 text-right">
                                            <button className="btns"  type="submit">Submit </button>
                                        </div>
                                    </div>
                                    </form>
                                </div>
                                
                                <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                                    <div className="add_info_sec">
                                        <div className="add_block">
                                            <i><img src="assets/images/add_icon_1.svg" alt="" /></i>
                                            <div className="r_info">
                                                <h3>OUR ADDRESS</h3>
                                                <p>Siam House,No-1/21,Sri Ramanamharashi Road,Sadashivanagar, Bengaluru</p>
                                            </div>
                                        </div>
                                        
                                        <div className="add_block">
                                            <i><img src="assets/images/add_icon_2.svg" alt="" /></i>
                                            <div className="r_info">
                                                <h3>PHONE NUMBER</h3>
                                                <p><a href="tel:918043779478">+91 80 4377 9478</a><br /><a href="tel:918660236315">+91 86602 36315</a></p>
                                            </div>
                                        </div>
                                        
                                        <div className="add_block">
                                            <i><img src="assets/images/add_icon_3.svg" alt="" /></i>
                                            <div className="r_info">
                                                <h3>MESSAGE US</h3>
                                                <p><a href="mail:info@kamalya.com">info@kamalya.com</a></p>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                                
                            </div>
                            
                        </div>
                    </div>

                    </section>
            </div>
            )
        }
    }
    export default Careers