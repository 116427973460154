import React from 'react';
/* import axios from "axios"; */
import Contactfrom from "../components/Contact_form"; 

/* import {Link} from 'react-router-dom'; */

class Contact extends React.Component{
    /*constructor(props) {
        super(props);
        this.state = {
          name: '',
          email: '',
          phone:'',
          subject:'',
          message: ''
        }
      }

      handleChange(event) {
        const field = event.target.id;
        if (field === "name") {
          this.setState({ name: event.target.value });
        }  else if (field === "email") {
            this.setState({ email: event.target.value });
        }else if (field === "phone") {
            const re = /^[0-9\b]+$/; //rules
            if (event.target.value === "" || re.test(event.target.value)) {
                this.setState({ phone: event.target.value });
            }
           // this.setState({ phone: event.target.value });
        } else if (field === "subject") {
            this.setState({ subject: event.target.value });
        }  else if (field === "message") {
          this.setState({ message: event.target.value });
        }
      }*/

     /* handleSubmit(event) {
          var self = this;
        event.preventDefault();  
        this.setState({ status: "Sending" });  
       
        var o =this.state;*/
        
        /* var post_uri =window.API_URL+'contact.php'; */
        /* var post_uri ='http://neelamsoft.com/kamalya/api/web/contacts';
        axios.post(post_uri, o).then(function (response) {
                if(response.data.error === 0 || response.data.error === '0'){

                    alert(response.data.message);
                    self.setState({name: "", email: "", phone:"", subject:"", message: ""});
                }else{
                    alert(response.data.message);
                    
                }
            })
            .catch(function (error) {
            alert(error); 
            });
      
      }*/

      
      
    render(){
        return(
            <div>
              {/*  <!-- Section --> */}
                <section>

               {/*  <!-- Inner Banner --> */}
           
                <div className="inner_banner info_contact_banner">
                    <div className="container">
                        <h1>Contact Information</h1>
                    </div>
                </div>

                {/* <!-- Team Sec --> */}
                <div className="info_contact">
                    <div className="container">
                        {/* <!-- Map --> */}
                        <div className="map_info">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.455800182096!2d77.57774026527004!3d13.00662061762701!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae16337dd99e89%3A0x6c9a0bda21227398!2sSadashiva%20Nagar%2C%20Armane%20Nagar%2C%20Bengaluru%2C%20Karnataka!5e0!3m2!1sen!2sin!4v1617123117804!5m2!1sen!2sin" title="Kamalya" width="100%" height="450"  allowFullScreen="" loading="lazy" style={{border:0 }}></iframe>
                        </div>
                        
                        
                        <div className="clearfix"></div>
                        <div className="row">
                            <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                                <h2 className="global_title">Contact Us</h2>
                                <Contactfrom></Contactfrom>
                            </div>
                            
                            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                                <div className="add_info_sec">
                                    <div className="add_block">
                                        <i><img src="assets/images/add_icon_1.svg" alt="" /></i>
                                        <div className="r_info">
                                            <h3>OUR ADDRESS</h3>
                                            <p>Siam House,No-1/21,Sri Ramanamharashi Road,Sadashivanagar,Bengaluru</p>
                                        </div>
                                    </div>
                                    
                                    <div className="add_block">
                                        <i><img src="assets/images/add_icon_2.svg" alt="" /></i>
                                        <div className="r_info">
                                            <h3>PHONE NUMBER</h3>
                                            <p><a href="tel:918043779478">+91 80 4377 9478</a><br /><a href="tel:918660236315">+91 86602 36315</a></p>
                                        </div>
                                    </div>
                                    
                                    <div className="add_block">
                                        <i><img src="assets/images/add_icon_3.svg" alt="" /></i>
                                        <div className="r_info">
                                            <h3>MESSAGE US</h3>
                                            <p><a href="mail:info@kamalya.com">info@kamalya.com</a></p>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            
                        </div>
                        
                    </div>
                </div>

                </section>

            </div>
            )
        };
    }

    

    
    export default Contact