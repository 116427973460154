import React from 'react';

/* import {Link} from 'react-router-dom'; */

class Hospitality extends React.Component{

    render(){
        return(
            <div>
                <section>

                   {/*  <!-- Inner Banner --> */}
                    <div className="inner_banner" style={{ backgroundImage: `url("../assets/images/banners/hospitalitybanner.jpg")`}}>
                        <div className="container">
                            <h1>Hospitality</h1>
                        </div>
                    </div>
                    {/* <!-- Realestate --> */}
                        <div className="inner_about">
                            <div className="container">
                                <div className="row flex-row-reverse">
                                    <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                                        <h2 className="global_title"><span>Hospitality</span></h2>
                                        <p>"Hospitality" is all about people. We are passionate to create one of the best experiences for our guests. Our team of hospitality combines personal interaction with creative ideas, this is what makes our hotels unique from others. We aim to inspire our guests through an innovative and holistic approach. We strive to deliver the best, Kamalya hotels is a new breed of hoteliers, creating high-performing hotels. We believe that modern changes lead the way to success. Our staff takes in-depth care from accommodation, hotels, resorts, and breakfast, nightclubs, and so on. </p>
                                    </div>
                                    <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                                        <figure><img src="../assets/images/allbrand/hospitality.jpg" alt="Hospitality" /></figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Team Sec --> */}
                        <div className="team_sec">
                            <div className="container">
                                <h2 className="global_title"><span>Brands</span></h2>
                                <div className="row">
                                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                        <div className="team_block">
                                            <div><img className="brand-image" src="../assets/images/brand/hospitality/brij.jpg" alt=""/></div>
                                            <h3>Brij Rama Hotel</h3>
                                            <p>Brij Rama hotels provide vivid experiences enabling you to paint your own memory image which lingers on forever. Be it our warm welcome, the scent of spirituality, aromas of our local food specialty, or serene sleep after an eventful day.</p>
                                            <a href="https://brijhotels.com/" target="_blank" rel="noreferrer" className="more_link">Go to Brand Page <i className="fa fa-long-arrow-right"></i></a>
                                        </div>
                                    </div>
                                    
                                    {/* <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                        <div className="team_block">
                                            <div><img className="brand-image" src="../assets/images/brand/hospitality/1589hotels.jpg" alt="team_img" /></div>
                                            <h3>1589 Hotels</h3>
                                            <p>1589 Hotels is an Indian hospitality company, drawing its legacy from Clarks Group of Hotels. We offer over 55 hotels in 35 cities. 1589 prides itself in making luxury affordable for all, with the assurance of efficient service and quality.</p>
                                            <a href="https://www.1589hotels.com/" className="more_link">Go to Brand Page <i className="fa fa-long-arrow-right"></i></a>
                                        </div>
                                    </div> */}

                                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                        <div className="team_block">
                                            <div><img className="brand-image" src="../assets/images/brand/hospitality/Clark-Exotica.jpg" alt=""/></div>
                                            <h3>Clarks Exotica Resort and Spa</h3>
                                            <p>The Clarks Exotica was intended to be a five-star clubhouse for residents, transcended to become one of Bengaluru’s preferred destinations for corporate conventions, weekend getaways, wedding destinations, and spacations.</p>
                                            <a href="https://www.clarksexotica.com/" target="_blank" rel="noreferrer" className="more_link">Go to Brand Page <i className="fa fa-long-arrow-right"></i></a>
                                        </div>
                                    </div>
                                    
                                </div>

                                <div className="row">
                                  
                                    {/* <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                        <div className="team_block">
                                            <div><img className="brand-image" src="../assets/images/brand/hospitality/clarks_group_of_hotels_up.jpg" alt=""/></div>
                                            <h3>Clarks Groups of Hotels UP</h3>
                                            <p>The Clarks Group of Hotels is one of the Leading Hotel Groups in Jaipur, Lucknow, Agra & Khajuraho, recognized throughout the nation for its unique flavor of hospitality.</p>
                                            <a href="https://www.hotelclarks.com/" target="_blank" rel="noreferrer" className="more_link">Go to Brand Page <i className="fa fa-long-arrow-right"></i></a>
                                        </div>
                                    </div> */}
                                    
                                </div>
                            </div>
                        </div>


                    </section>
            </div>
            )
        }
    }
    export default Hospitality