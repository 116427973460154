import React from 'react';
//import bgimg from '../../public/assets/images/banners/aboutus.jpg';

/* import {Link} from 'react-router-dom'; */

class About extends React.Component{
    render(){
        return(
            <div>
                <section>

                   {/*  <!-- Inner Banner --
                   {/*  <!-- Inner Banner --> */}
                    <div className="inner_banner" style={{  backgroundImage: `url("assets/images/banners/aboutus.jpg")` }}>
                        <div className="container">
                            <h1>About us</h1>
                        </div>
                    </div>
                    {/* <!-- About --> */}
                        <div className="inner_about">
                            <div className="container">
                                <div className="row flex-row-reverse">
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <h2 className="global_title"><span>Who we are</span></h2>
                                        <p>Kamalya Group has businesses across Textiles, Hospitality, Wellness, Food & Beverages and Real Estate. </p>
                                        <p>Textile is one of the first business, has over 1000 employees working across 3 factories producing quality menswear with various International and Domestic brands. Our in-house menswear brand IMYoung sells across all major e-commerce portals promising new styles, latest designs, modern stitching, quality material all at affordable price. IMYoung strives to make the most comfortable and long-lasting clothing in its segment.</p>
                                        <p>Hospitality division has over 55 hotels and growing across Brijhotels- luxury experiences & Nepal and Clarks Exotica Sap & Convention center – the largest garden hotel in the garden city of Bengaluru, spread across 150 acres of greenery.</p>
                                        <p>Switch Wellness, our wellness center is a progressive way of living which offers holistic living solutions for both body and mind. Unique offerings customized to every individual’s nature, body, behavior and genes making health not a superficial achievement but a behavioral habit.  </p>
                                        <p>Dolci Desserts, neighborhood café, offers the best cheesecake in town. Indoor & Outdoor seating spaces, walls adorned with collectable artwork, greenery, cozy seating with ample of natural lights makes Dolci a perfect place for meetings, hanging out with friends and a quite work place to make those strategies with a hot cup of coffee chocolate cake as company.</p>
                                        <p>Real estate offers well planned lands with all modern facilities and baiscs like water lines, electricity lines, walk-away, sunrise and sunset points, wide greenery around the plots for making your perfect dream house.</p>
                                    </div>
                                    {/* <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                                        <figure><img src="assets/images/abput_img_1.jpg" alt="abput_img" /></figure>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        {/* <!-- Team Sec --> */}
                        <div className="team_sec">
                            <div className="container">
                                <h2 className="global_title"><span>Our Team</span></h2>
                                <div className="row">
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                        <div className="team_block">
                                            <figure><img src="assets/images/team_img_1.png" alt=""/></figure>
                                            <h3>SRI ARVIND KUMAR <small>Chairman</small></h3>
                                            <p>Sri Arvind Kumar was born and brought up in Varanasi, successfully started one of India’s first premium five-star chain of hotels, the Clarks Group of Hotels. Along with this, he established several successful businesses, mainly in textiles and trade firms. </p>
                                            <a href="/about/arvind-kumar" className="more_link">Read More <i className="fa fa-long-arrow-right"></i></a>
                                            <div className="team_social">
                                                {/* <a href="/"><i className="fa fa-twitter"></i></a>
                                                <a href="/"><i className="fa fa-facebook"></i></a> 
                                                <a href="/kamalya"><i className="fa fa-instagram"></i></a>*/}
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                        <div className="team_block">
                                            <figure><img src="assets/images/team_img_2.png" alt="team_img" /></figure>
                                            <h3>MR. VIVEK KUMAR <small>Managing Director</small></h3>
                                            <p>Mr. Vivek Kumar is born and brought up in the city of Lord Shiva - the oldest city in the world, Varanasi. He is an astute businessman and philanthropist with the mind of a free spirit creative thinker. </p>
                                            <a href="/about/vivek-kumar" className="more_link">Read More <i className="fa fa-long-arrow-right"></i></a>
                                            <div className="team_social">
                                                {/* <a href="/"><i className="fa fa-twitter"></i></a>
                                                <a href="/"><i className="fa fa-facebook"></i></a> */}
                                                {/* <a target="_blank" href="https://www.linkedin.com/in/vivek-kumar-9a415215/"><i className="fa fa-linkedin-square"></i></a> */}
                                            </div>
                                        </div>
                                    </div>

                                    
                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <div className="team_block">
                                            <figure><img src="assets/images/team_img_3.png" alt="" /></figure>
                                            <h3>MR. M BALAJI <small>CEO</small></h3>
                                            <p>M Balaji is the Chief Executive Officer at Clarks Exotica Convention Resort and Spa, Bangalore. With nearly two decades of experience in operations, events, business development, sales, management, and marketing leadership roles</p>
                                            <a href="/about/m-balaji" className="more_link">Read More <i className="fa fa-long-arrow-right"></i></a>
                                            <div className="team_social">
                                                {/* <a href="/"><i className="fa fa-twitter"></i></a>
                                                <a href="/"><i className="fa fa-facebook"></i></a> */}
                                                {/* <a target="_blank" href="https://www.linkedin.com/in/balaji-m-062a891b4/"><i className="fa fa-linkedin-square"></i></a> */}
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <div className="team_block">
                                            <figure><img src="assets/images/team_img_4.png" alt="" /></figure>
                                            <h3>MR. UDIT KUMAR <small>Director</small></h3>
                                            <p>Coming from a family with three generations involved in the hospitality industry it was only natural for Mr. Udit Kumar to venture into this segment and succeed. </p>
                                            <a href="/about/udit-kumar" className="more_link">Read More <i className="fa fa-long-arrow-right"></i></a>
                                            <div className="team_social">
                                                {/* <a href="/"><i className="fa fa-twitter"></i></a>
                                                <a href="/"><i className="fa fa-facebook"></i></a>
                                                <a href="/"><i className="fa fa-instagram"></i></a> */}
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <div className="team_block">
                                            <figure><img src="assets/images/team_img_5.png" alt="" /></figure>
                                            <h3>MR. ARNAV KUMAR <small>Director</small></h3>
                                            <p>The youngest member from a family of business mavericks, Arnav Kumar is eager to revolutionize the Textile and HORECA (Hotels, Restaurants, and Catering) industry with his energy, passion, and vision.  </p>
                                            <a href="/about/arnav-kumar" className="more_link">Read More <i className="fa fa-long-arrow-right"></i></a>
                                            <div className="team_social">
                                                {/* <a href="/"><i className="fa fa-twitter"></i></a>
                                                <a href="/"><i className="fa fa-facebook"></i></a> */}
                                                {/* <a target="_blank" href="https://www.linkedin.com/in/arnav-kumar-b792951a6/"><i className="fa fa-linkedin-square"></i></a> */}
                                            </div>
                                        </div>
                                    </div>
                                    
                                    {/* <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <div className="join_team_block">
                                            <h3>Join Our<br/>Team</h3>
                                            <p>Suspendisse efficitur augue quis purus bibendum congue. Integer eu molestie lorem, vel vulputate risus. Pellentesque.</p>
                                            <a href="/careers" className="btns bordered">Learn More</a>
                                        </div> 
                                    </div>*/}
                                    
                                    
                                </div>
                            </div>
                        </div>


                    </section>
            </div>
            )
        }
    }
    export default About