import React from 'react';

/* import {Link} from 'react-router-dom'; */

class Termsservices extends React.Component{
    render(){
        return(
            <div>
               
                {/*  <!-- Section --> */}
                <section>
                <div class="typo_sec">
                    <div class="container">
                        <h1 class="text-center"> Terms & services</h1>
                        <p>Nullam nulla lorem, rutrum a laoreet non, auctor cursus orci. Donec libero odio, laoreet vel consequat in, viverra non diam. Quisque tincidunt leo tellus. In non fringilla metus, ac euismod ipsum. Aenean imperdiet fermentum porta. Praesent sed est tincidunt, mattis enim et, aliquam turpis. Suspendisse tempor, sem nec finibus pellentesque, justo sem aliquet odio, at consectetur purus lectus sed augue. Vivamus ac justo sed erat vehicula pretium vel a enim. Suspendisse potenti. Nunc pulvinar, magna et finibus ullamcorper, augue metus porta mauris, sed efficitur tortor justo sit amet sem. Vestibulum elementum mi et mauris feugiat cursus sit amet ac est. Quisque sapien lectus, sodales vel efficitur ac, vehicula sed turpis. Integer faucibus rhoncus tellus, in suscipit enim aliquam a. Donec tristique odio vel neque tristique lacinia. Proin vel finibus nulla, viverra lobortis leo. Maecenas tempus volutpat lacus a suscipit. Quisque porttitor purus in lacus convallis, id fermentum mauris tristique. Maecenas at ultrices tortor. Nunc ultricies tortor maximus lacus tristique fermentum. Integer eu vulputate urna. Vivamus iaculis lectus justo, quis bibendum velit pharetra eu. Aenean sed mauris nibh. Vivamus non justo sed dolor euismod gravida eu at enim. Nulla blandit interdum augue quis tempus. Sed in est varius, eleifend enim non, varius est.</p>
                        
                        <h2>Nunc a tristique dui</h2>
                        <p>are. Ut tincidunt faucibus nisl, convallis ullamcorper tortor fermentum nec. Maecenas ornare suscipit ornare. Suspendisse quis placerat enim, ac condimentum est. Aliquam orci purus, tempus vel massa malesuada, posuere vestibulum ligula. Phasellus vitae tincidunt nunc. Curabitur pretium semper ultricies. Suspendisse leo nisl, maximus eu erat at, ultrices placerat felis. Nullam et dui ut urna rhoncus bibendum. Nunc a tristique dui, vel tempor ipsum. Duis bibendum sed quam ac mollis. Suspendisse potenti. Sed posuere nec arcu quis volut</p>
                        
                        <h3>Nunc a tristique dui</h3>
                        <p>Nunc a tristique dui, vel tempor ipsum. Duis bibendum sed quam ac mollis. Suspendisse potenti. Sed posuere nec arcu quis volutpat. Sed vitae nisl mollis, tincidunt quam vel, ultrices nibh. Sed rutrum justo in purus sodales mollis. Nam non purus diam. In tempor odio eu sem volutpat, vel bibendum lacus ornare. Ut tincidunt faucibus nisl, convallis ullamcorper tortor fermentum nec. Maecenas ornare suscipit ornare. Suspendisse quis placerat enim, ac condimentum est. Aliquam orci purus, tempus vel massa malesuada, posuere vestibulum ligula. Phasellus vitae tincidunt nunc. Curabitur pretium semper ultricies. Suspendisse leo nisl, maximus eu erat at, ultrices placerat felis. Nullam et dui ut urna rhoncus bibendum. Nunc a tristique dui, vel tempor ipsum. Duis bibendum sed quam ac mollis. Suspendisse potenti. Sed posuere nec arcu quis volutpat. Sed vitae nisl mollis, tincidunt quam vel, ultrices nibh. Sed rutrum justo in purus sodales mollis. Nam non purus diam. In tempor odio eu sem volutpat, vel bibendum lacus ornare. Ut tincidunt faucibus nisl, convallis ullamcorper tortor fermentum nec. Maecenas ornare suscipit ornare. Suspendisse quis placerat enim, ac condimentum est. Aliquam orci purus, tempus vel massa malesuada, posuere vestibulum ligula. Phasellus vitae tincidunt nunc. Curabitur pretium semper ultricies. Suspendisse leo nisl, maximus eu erat at, ultrices placerat felis. Nullam et dui ut urna rhoncus bibendum.</p>
                        
                        <ul>
                            <li><a href="/#">Phasellus vitae tincidunt nun</a></li>
                            <li><a href="/#">cies. Suspendisse leo nisl, maximus eu </a></li>
                            <li><a href="/#">Sed rutrum justo in purus sodales mollis.</a></li>
                            <li><a href="/#">leo nisl, maximus eu erat at, ultrices placerat </a></li>
                            <li><a href="/#">entum nec. Maecenas ornare suscipit ornare. Suspendisse quis plac</a></li>
                        </ul>
                        
                        
                        <p>Nunc a tristique dui, vel tempor ipsum. Duis bibendum sed quam ac mollis. Suspendisse potenti. Sed posuere nec arcu quis volutpat. Sed vitae nisl mollis, tincidunt quam vel, ultrices nibh. Sed rutrum justo in purus sodales mollis. Nam non purus diam. In tempor odio eu sem volutpat, vel bibendum lacus ornare. Ut tincidunt faucibus nisl, convallis ullamcorper tortor fermentum nec. Maecenas ornare suscipit ornare. Suspendisse quis placerat enim, ac condimentum est. Aliquam orci purus, tempus vel massa malesuada, posuere vestibulum ligula. Phasellus vitae tincidunt nunc. Curabitur pretium semper ultricies. Suspendisse leo nisl, maximus eu erat at, ultrices placerat felis. Nullam et dui ut urna rhoncus bibendum. Nunc a tristique dui, vel tempor ipsum. Duis bibendum sed quam ac mollis. Suspendisse potenti. Sed posuere nec arcu quis volutpat. Sed vitae nisl mollis, tincidunt quam vel, ultrices nibh. Sed rutrum justo in purus sodales mollis. Nam non purus diam. In tempor odio eu sem volutpat, vel bibendum lacus ornare. Ut tincidunt faucibus nisl, convallis ullamcorper tortor fermentum nec. Maecenas ornare suscipit ornare. Suspendisse quis placerat enim, ac condimentum est. Aliquam orci purus, tempus vel massa malesuada, posuere vestibulum ligula. Phasellus vitae tincidunt nunc. Curabitur pretium semper ultricies. Suspendisse leo nisl, maximus eu erat at, ultrices placerat felis. Nullam et dui ut urna rhoncus bibendum.</p>
                        
                        
                        
                    </div>
                </div>
                </section>
            </div>
            )
        }
    }
    export default Termsservices