import React from 'react';

/* import {Link} from 'react-router-dom'; */

class Arnav extends React.Component{
    render(){
        return(
            <div>
                <section>

                   {/*  <!-- Inner Banner --> */}
                    <div className="inner_banner">
                        <div className="container">
                            {/* <h1>ARVIND KUMAR</h1> */}
                        </div>
                    </div>
                    {/* <!-- About --> */}
                        <div className="inner_about">
                            <div className="container">
                                <div className="row flex-row-reverse">
                                    <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                                        <h2 className="global_title"><span>MR. ARNAV KUMAR</span></h2>
                                        <h4>DIRECTOR</h4>
                                        <p></p>
                                        <p>The youngest member from a family of business mavericks, Arnav Kumar is eager to revolutionize the Textile and HORECA (Hotels, Restaurants, and Catering) industry with his energy, passion, and vision.  Arnav is keen on taking forward the legacy. He is the Executive Director at Indus Intex Private Limited, Indus TECHPARK Private Limited, Brijrama Hospitality Private Limited, The Indian Textiles Company (Kolkata) Private Limited, Cofounder of Dolci Desserts & Pizzeria. He was born and brought up in the garden city of India, Bengaluru.  Arnav completed his International Baccalaureate at the United World College South East Asia, Singapore, and graduated with Bachelors in Engineering from the University of Warwick, UK.</p>
                                        <p>Arnav’s keen interest in wellness has taken this passion forward and can be experienced in our wellness brand – Switch. Wellness should be accessible to all. Sound body needs sound mind. Meditation should be looked at alternative to stress management. His research and keen interest in the subject has led to the discovery of various alternative treatments to cure illnesses specifically related to diabetes and dermatology.  Arnav aims to spread awareness about sustainable and healthy living and to make people aware of the social and environmental impacts their everyday decisions have through his health resort. A hotelier at heart, Arnav also Co-Founded Dolci Desserts & Pizzeria with his mother, Ms. Shipra Kumar. In his spare time, Arnav loves to play football and cook.</p>
                                        <br/>
                                        <div className="team_social">
                                                {/* <a href="/"><i className="fa fa-twitter"></i></a>
                                                <a href="/"><i className="fa fa-facebook"></i></a> */}
                                                <a target="_blank" href="https://www.linkedin.com/in/arnav-kumar-b792951a6/"><i className="fa fa-linkedin-square"></i></a>
                                            </div>
                                    </div>
                                    <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                                        <figure><img src="/assets/images/teams/4.jpg" alt="MR. ARNAV KUMAR" /></figure>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>
            </div>
            )
        }
    }
    export default Arnav