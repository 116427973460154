import React from 'react';

/* import {Link} from 'react-router-dom'; */

class Food extends React.Component{

    render(){
        return(
            <div>
                <section>

                   {/*  <!-- Inner Banner --> */}
                    <div className="inner_banner"   style={{  backgroundImage: `url("../assets/images/banners/food_beveragesbanner.jpg")` }}>
                        <div className="container">
                            <h1>Food and Beverages</h1>
                        </div>
                    </div>
                    {/* <!-- Food --> */}
                        <div className="inner_about">
                            <div className="container">
                                <div className="row flex-row-reverse">
                                    <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                                        <h2 className="global_title"><span>Food and Beverages</span></h2>
                                        <p>Our Food and Beverage industry engages itself in the provision of food and beverage. We fulfill the basic needs of customers for food and beverages. We ensure hygienic transportation and consequently the highest product quality with our process and materials expertise.</p>
                                    </div>
                                    <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                                        <figure><img src="../assets/images/allbrand/foodandbeverages.jpg" alt="Food and Beverages" /></figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Team Sec --> */}
                        <div className="team_sec">
                            <div className="container">
                                <h2 className="global_title"><span>Brands</span></h2>
                                <div className="row">
                                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                        <div className="team_block">
                                            <div><img className="brand-image" src="../assets/images/brand/fnb/dolcidesserts.jpg" alt=""/></div>
                                            <h3>Dolci Dessert</h3>
                                            <p>The desserts here are the superstars even though Dolci does equally good savory snacks and bites.</p>
                                            <a href="https://lbb.in/bangalore/dolci-dessert-cunningham-road-bangalore/" target="_blank" rel="noreferrer" className="more_link">Go to Brand Page <i className="fa fa-long-arrow-right"></i></a>
                                        </div>
                                    </div>
                                    
                                </div>

                            </div>
                        </div>


                    </section>
            </div>
            )
        }
    }
    export default Food