import React from 'react';

/* import {Link} from 'react-router-dom'; */

class Udit extends React.Component{
    render(){
        return(
            <div>
                <section>

                   {/*  <!-- Inner Banner --> */}
                    <div className="inner_banner">
                        <div className="container">
                            {/* <h1>ARVIND KUMAR</h1> */}
                        </div>
                    </div>
                    {/* <!-- About --> */}
                        <div className="inner_about">
                            <div className="container">
                                <div className="row flex-row-reverse">
                                    <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                                        <h2 className="global_title"><span>MR. UDIT KUMAR</span></h2>
                                        <h4>DIRECTOR</h4>
                                        <p></p>
                                        <p>Coming from a family with three generations involved in the hospitality industry it was only natural for Mr. Udit Kumar to venture into this segment and succeed. He and his cousin brother Mr. Anant Kumar established the 1589 Group of Hotels and currently manages 35 hotels across India & Nepal. Hotels ranges from  Budget, Corporate, Boutique, Leisure, Health and Wellness. Born and brought up in the Silicon Valley of India, Bengaluru, Mr. Udit completed his International Baccalaureate at The International School Bangalore and graduated with Bachelors in Accounting & Finance from, University of Warwick, UK. Creativity and independence has been his qualities right from the start and thatb explains starting his own brand of hotels on his own. </p>
                                        <p>He is determined to achieve his goal and has successfully instilled his attitude and vision in his people. Today, 1589 Hotels has proudly implemented its vision. To develop an environment for independence and growth of our human resource to empathize with our customers thereby predicting and fulfilling their aspirations, providing them the ultimate experience of comfort and solace under an ambiance of love. At the age of 26, Mr. Udit has proved himself as a young entrepreneur brimming with confidence. He has involved himself in several philanthropic activities, notably his contribution towards the Heart Care Foundation (funding operations for the underprivileged, especially females). Mr. Udit loves quizzes and playing strategy-based board games as a hobby.</p>
                                    </div>
                                    <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                                        <figure><img src="/assets/images/teams/3.jpg" alt="MR. UDIT KUMAR" /></figure>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>
            </div>
            )
        }
    }
    export default Udit