import React from 'react';

/* import {Link} from 'react-router-dom'; */

class Brands extends React.Component{
    render(){
        return(
            <div>
              {/*  <!-- Section --> */}
                <section>
                <div className="branding_sec">
                   {/*  <!-- Branding Block --> */}
                    <div className="branding_block block_1">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 align-self-center">
                                    <h2 className="global_title"><span>Textile</span></h2>
                                    <p>"Textile sector" - one of the oldest industries in the Indian economy. Our textile industry is primarily concerned with the design, production, and distribution of cloth and clothing. Our Textiles are one of the leading sellers in India. We design, manufacture, and distribute high-quality products. While keeping pace with changes in fashion and technology, our textile industry has always remained faithful to its core value of high production, quality standards, and customer satisfaction. With the use of advanced technology and modern equipment, we aim to deliver the best clothes to our customers. </p>
                                    <a href="/industries/textile" className="btns">READ MORE <i class="fa fa-long-arrow-right"></i> </a>                                     
                                </div>
                                
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 align-self-center">
                                    <figure><img src="assets/images/allbrand/textile.jpg" alt="Textile" /></figure>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    
                   {/*  <!-- Branding Block --> */}
                    <div className="branding_block yellow block_2">
                        <div className="container">
                            <div className="row flex-row-reverse">
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 align-self-center">
                                    <h2 className="global_title"><span>Hospitality </span></h2>
                                    <p>"Hospitality" is all about people. We are passionate to create one of the best experiences for our guests. Our team of hospitality combines personal interaction with creative ideas, this is what makes our hotels unique from others. We aim to inspire our guests through an innovative and holistic approach. We strive to deliver the best, Kamalya hotels is a new breed of hoteliers, creating high-performing hotels. We believe that modern changes lead the way to success. Our staff takes in-depth care from accommodation, hotels, resorts, and breakfast, nightclubs, and so on. </p>
                                    <a href="/industries/hospitality" className="btns">READ MORE <i class="fa fa-long-arrow-right"></i></a>
                                    {/* <a href="/industries/hospitality" className="more_link"> </a>  */}
                                </div>
                                
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 align-self-center">
                                    <figure><img src="assets/images/allbrand/hospitality.jpg" alt="Hospitality" /></figure>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    
                   {/*  <!-- Branding Block --> */}
                    <div className="branding_block">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 align-self-center">
                                    <h2 className="global_title"><span>Wellness</span></h2>
                                    <p>When people think about wellness in terms of physical health — nutrition, exercise, weight management comes to mind but it is so much more than this. Our wellness centers strive to give the best to the customers, make you organize, understand, and balance your own growth and development. Our aim is to ensure high-quality patient and client services.</p>
                                    <p>Our wellness centers offer services such as fitness services, personal training, and nutrition consulting. However, understanding the term wellness is not the same for everyone depending on individual perspective, but it is generally referred to as wellness of mind, body, and soul.</p>
                                    <a href="/industries/wellness" className="btns">READ MORE <i class="fa fa-long-arrow-right"></i> </a> 
                                </div>
                                
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 align-self-center">
                                    <figure><img src="assets/images/allbrand/wellness.jpg" alt="Wellness" /></figure>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    
                    {/* <!-- Branding Block --> */}
                    <div className="branding_block yellow block_3">
                        <div className="container">
                            <div className="row flex-row-reverse">
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 align-self-center">
                                    <h2 className="global_title"><span>Real Estate</span></h2>
                                    <p>Our real estate industry believes in building aesthetical and structurally remarkable projects. We treat everyone with the best in class advice whether you аrе a fіrѕt-tіmе buyer оr соrроrаtе bоdу. Our aim is to build long-term rеlаtіоnѕhірѕ with our customers and help them to take a bеttеr рrореrtу dесіѕіоnѕ. We ѕеrve our сuѕtоmеrѕ with a wide-range of ѕресіаltу and lосаl knowledge in buying, ѕеllіng аnd rеntіng rеѕіdеntіаl аnd соmmеrсіаl spaces. Our team is always ready for the property requisites. Explore our real estate industries which are rіghtlу fit for уоu аnd lеаvе the rеѕt оn uѕ.</p>
                                    <a href="/industries/real-estate" className="btns">READ MORE <i class="fa fa-long-arrow-right"></i> </a> 
                                </div>
                                
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 align-self-center">
                                    <figure><img src="assets/images/brand_image_4.jpg" alt="" /></figure>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    
                   {/*  <!-- Branding Block --> */}
                    <div className="branding_block">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 align-self-center">
                                    <h2 className="global_title"><span>Food and Beverages</span></h2>
                                    <p>Our Food and Beverage industry engages itself in the provision of food and beverage. We fulfill the basic needs of customers for food and beverages. We ensure hygienic transportation and consequently the highest product quality with our process and materials expertise.</p>
                                    <a href="/industries/food-and-beverages" className="btns">READ MORE <i class="fa fa-long-arrow-right"></i> </a> 
                                </div>
                                
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 align-self-center">
                                    <figure><img src="assets/images/allbrand/foodandbeverages.jpg" alt="Food and Beverages" /></figure>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    
                    
                    
                </div>
                </section>
            </div>
            )
        }
    }
    export default Brands