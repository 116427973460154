/*import React,{Component} from 'react'; 
import {Link} from 'react-router-dom';
*/
 import React from 'react'; 
 import {NavLink} from 'react-router-dom'

class Header extends React.Component{
    render(){
        return(
            <div>
				{/*  <!-- Header --> */}
				<header>

				{/* <!-- Top Part --> */}
				<div className="top_part">
					<div className="container">
						<div className="ph"><i className="fa fa-phone"></i> <span>+91 80 4377 9478 / +91 86602 36315</span></div>
						<div className="top_social">
							{/* <a href="/"><i className="fa fa-twitter"></i></a>
							<a href="/"><i className="fa fa-facebook-f"></i></a>
							<a href="/"><i className="fa fa-instagram"></i></a> */}
						</div>
					</div> 
				</div>

				{/* <!-- Nav Sec --> */}
				<div className="nav_sec">
					<div className="container">
						<nav className="navbar navbar-expand-md navbar-light">
						  
						  <NavLink className="navbar-brand" exact  to='/'><img src="/assets/images/logo.svg" alt="" /></NavLink>
						  <button className="navbar-toggler navbar-toggler-right collapsed" type="button" data-toggle="collapse" data-target="#Navigation" aria-controls="Navigation" aria-expanded="false" aria-label="Toggle navigation"><span></span><span></span><span></span></button>
						
						  <div className="collapse navbar-collapse" id="Navigation">
							<ul className="navbar-nav mx-auto">
							<li className="nav-item"><NavLink className="nav-link" exact activeClassName="active" to='/' >Home</NavLink></li>
 							<li className="nav-item"><NavLink className="nav-link" activeClassName="active" to='/about'>About Us</NavLink></li>
							  
							 <li className="nav-item dropdown">							  
								<NavLink className="nav-link dropdown-toggle" activeClassName="active" to="/industries" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Our Industries</NavLink>
								<div className="dropdown-menu" aria-labelledby="navbarDropdown">
								<NavLink className="dropdown-item" exact  to='/industries'>Industries We Serve</NavLink>
								<NavLink className="dropdown-item" to='/industries/textile'>Textile</NavLink>
								<NavLink className="dropdown-item" to='/industries/hospitality'>Hospitality</NavLink>
								<NavLink className="dropdown-item" to='/industries/wellness'>Wellness</NavLink>
								<NavLink className="dropdown-item" to='/industries/food-and-beverages'>Food and Beverages</NavLink>
								<NavLink className="dropdown-item" to='/industries/real-estate'>Real Estate</NavLink>
								</div>
							</li>
							  
							<li className="nav-item"><NavLink className="nav-link" activeClassName="active" to="/careers" >Careers</NavLink></li>
							<li className="nav-item"><NavLink className="nav-link" activeClassName="active" to="/blogs">Blogs</NavLink></li>
							<li className="nav-item"><NavLink className="nav-link" activeClassName="active" to="/awards">Awards</NavLink></li>
							<li className="nav-item"><NavLink className="nav-link" activeClassName="active" to="/contact">Contacts Us </NavLink></li>
							</ul>
						  </div>
						</nav>
					</div>
				</div>
				</header>

            </div>

        )
        }
    }
export default Header;
