/* import logo from './logo.svg'; */
import React from 'react';
import './App.css';
import {BrowserRouter as Router,Route,Switch} from 'react-router-dom'; 
/* import './pages/Home'*/
import Home from './pages/Home';
import About from './pages/About';
import Careers from './pages/Careers';
import Blogs from './pages/Blogs';
import Blogsview from './pages/Blogsview';

import Awards from './pages/Awards';
import Contact from './pages/Contact'; 
import Brands from './pages/Brands'; 
import Textile from './pages/Textile'; 
import Wellness from './pages/Wellness'; 
import Hospitality from './pages/Hospitality'; 
import Realestate from './pages/Realestate';

import Arvind from './pages/teams/Arvind';
import Vivek from './pages/teams/Vivek';
import Balaji from './pages/teams/Balaji';
import Udit from './pages/teams/Udit';
import Arnav from './pages/teams/Arnav';

import Food from './pages/Food';
import Termsservices from './pages/Terms-services';
import Privacypolicy from './pages/Privacy-policy';


import Header from './components/Header';
import Footer from './components/Footer';
import Notfound from './components/Notfound';

function App() {
  return (
    <div>
     
    <Router>
    <Header></Header>
          <Switch>
            <Route exact path='/' component={Home}/>
            <Route exact  path='/about/' component={About}/>
            <Route  path='/about/arvind-kumar' component={Arvind}/>
            <Route  path='/about/vivek-kumar' component={Vivek}/>
            <Route  path='/about/m-balaji' component={Balaji}/>
            <Route  path='/about/udit-kumar' component={Udit}/>
            <Route  path='/about/arnav-kumar' component={Arnav}/>
            <Route  path='/careers' component={Careers}/>
            <Route  path='/blogs' component={Blogs}/>
            <Route  path='/blogsview/:blogId' component={Blogsview}/>
            <Route  path='/awards' component={Awards}/>
            <Route  path='/contact' component={Contact}/>
            <Route exact path='/industries/' component={Brands}/>
            <Route  path='/industries/textile' component={Textile}/>
            <Route  path='/industries/wellness' component={Wellness}/>
            <Route  path='/industries/hospitality' component={Hospitality}/>
            <Route  path='/industries/food-and-beverages' component={Food}/>
            <Route  path='/industries/real-estate' component={Realestate}/>
            <Route  path='/terms-services' component={Termsservices}/>
            <Route  path='/privacy-policy' component={Privacypolicy}/>
            
            
            <Route path='*' component={Notfound} />
          
          </Switch>
          <Footer></Footer>
      </Router>

    </div>
    
    /*<div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>*/
  );
}

export default App;
