import React from 'react';

/* import {Link} from 'react-router-dom'; */

class Wellness extends React.Component{

    render(){
        return(
            <div>
                <section>

                   {/*  <!-- Inner Banner --> */}
                    <div className="inner_banner"  style={{  backgroundImage: `url("../assets/images/banners/wellnessbanner.jpg")` }}>
                        <div className="container">
                            <h1>Wellness</h1>
                        </div>
                    </div>
                    {/* <!-- Wellness --> */}
                        <div className="inner_about">
                            <div className="container">
                                <div className="row flex-row-reverse">
                                    <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                                        <h2 className="global_title"><span>Wellness</span></h2>
                                        <p>When people think about wellness in terms of physical health — nutrition, exercise, weight management comes to mind but it is so much more than this. Our wellness centers strive to give the best to the customers, make you organize, understand, and balance your own growth and development. Our aim is to ensure high-quality patient and client services.</p>
                                    {/* <p>Our wellness centers offer services such as fitness services, personal training, and nutrition consulting. However, understanding the term wellness is not the same for everyone depending on individual perspective, but it is generally referred to as wellness of mind, body, and soul.</p> */}
                                    <p>The Switch is holistic health, wellness, and lifestyle brand rooted in Indian heritage amalgamating traditions with Western advancements. We are a lifestyle companion to help maintain good health.</p>
                                    </div>
                                    <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                                        <figure><img src="../assets/images/allbrand/wellness.jpg" alt="Wellness" /></figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Team Sec --> */}
                        <div className="team_sec">
                            <div className="container">
                                <h2 className="global_title"><span></span></h2>
                                <div className="row">
                                   <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                        <div className="team_block">
                                            <div><img className="brand-image" src="../assets/images/brand/wellness/group_class.jpg" alt="Group classes"/></div>
                                            <h3>Group classes</h3>
                                            <p>Developed and taught by a team of industry experts who nurture your potential, motivate you, and inspire you to improve.</p>
                                            <a href="https://switchyourlife.in/at-centre/" target="_blank" rel="noreferrer" className="more_link">Go to Brand Page <i className="fa fa-long-arrow-right"></i></a>
                                        </div>
                                    </div> 
                                    
                                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                        <div className="team_block">
                                            <div><img className="brand-image" src="../assets/images/brand/wellness/nutriton.png" alt="NUTRITON"/></div>
                                            <h3>NUTRITON</h3>
                                            <p>Nutrition isn’t healthy food but rather the food that is right for you. The right and balanced diet can help reduce the risk of many health concerns and is vital to achieving your goals.</p>
                                            <a href="https://switchyourlife.in/nutrition/" target="_blank" rel="noreferrer" className="more_link">Go to Brand Page <i className="fa fa-long-arrow-right"></i></a>
                                        </div>
                                    </div>                                     
                                    
                                </div>

                                <div className="row">
                                   <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                        <div className="team_block">
                                            <div><img className="brand-image" src="../assets/images/brand/wellness/personal_training.png" alt="PERSONAL TRAINING"/></div>
                                            <h3> PERSONAL TRAINING  </h3>
                                            <p>A personal trainer is essential to achieving your fitness goals by assisting you, motivating you, giving expert advice, and more.</p>
                                            <a href="https://switchyourlife.in/personal-training-2/" target="_blank" rel="noreferrer" className="more_link">Go to Brand Page <i className="fa fa-long-arrow-right"></i></a>
                                        </div>
                                    </div> 
                                    
                                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                        <div className="team_block">
                                            <div><img className="brand-image" src="../assets/images/brand/wellness/yoga.jpg" alt=""/></div>
                                            <h3>YOGA</h3>
                                            <p>Yoga, the ancient Indian practice is a group of physical, mental, and spiritual exercises  that heal, restore, and maintain your body and mind alike.</p>
                                            <a href="https://switchyourlife.in/yoga/" target="_blank" rel="noreferrer" className="more_link">Go to Brand Page <i className="fa fa-long-arrow-right"></i></a>
                                        </div>
                                    </div>                                     
                                    
                                </div>

                                <div className="row">
                                   <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                        <div className="team_block">
                                            <div><img className="brand-image" src="../assets/images/brand/wellness/physiotherapy.png" alt=""/></div>
                                            <h3>PHYSIOTHERAPY</h3>
                                            <p>Physiotherapy is the treatment to restore and maintain your mobility and well being by experienced professionals who know your body best.</p>
                                            <a href="https://switchyourlife.in/physiotherapy/" target="_blank" rel="noreferrer" className="more_link">Go to Brand Page <i className="fa fa-long-arrow-right"></i></a>
                                        </div>
                                    </div> 
                                    
                                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                        <div className="team_block">
                                            <div><img className="brand-image" src="../assets/images/brand/wellness/spa.png" alt=""/></div>
                                            <h3>KAMALYA SPA</h3>
                                            <p>Our treatments restore, rejuvenate, and refresh your body and mind alike with a variety of added benefits like better sleep and elevated mood. </p>
                                            <a href="https://switchyourlife.in/spa" target="_blank" rel="noreferrer" className="more_link">Go to Brand Page <i className="fa fa-long-arrow-right"></i></a>
                                        </div>
                                    </div>                                     
                                    
                                </div>
                               
                            </div>
                        </div>


                    </section>
            </div>
            )
        }
    }
    export default Wellness