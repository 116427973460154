import React from 'react';

/* import {Link} from 'react-router-dom'; */

class Notfound extends React.Component{
    render(){
        return(
            <div>
              
               <section>
                    <div className="typo_sec">
                        <div className="container">
                            <h1 className="text-center">Page Not Found !</h1>
                                    
                            
                        </div>
                    </div> 

                    </section>
            </div>
            )
        } 
    }
    export default Notfound