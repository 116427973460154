import React from 'react';

/* import {Link} from 'react-router-dom'; */

class Awards extends React.Component{

    componentDidMount () {
		const script = document.createElement("script");
		//script.src = "../../public/assets/js/testimonials.js";
		script.src = "/assets/js/testimonials.js";
		script.async = true;
	
		document.body.appendChild(script);
	}

    render(){
        return(
            <div>
                <section>

                <div className="inner_banner"  style={{  backgroundImage: `url("assets/images/banners/awardsbanners.jpg")` }}>
                        <div className="container">
                            <h1>Awards</h1>
                        </div>
                    </div>
 {/* <!-- About --> */}
     <div className="inner_about">
         <div className="container">

         <div class="container">
			<div className="row justify-content-center">
				<div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
					<a href='#clarks' className="navbar-award">
					<div className="service">
						<h3>Clarks Exotica Awards</h3>
					</div>
					</a>
				</div>
				<div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
				<a href='#brijrama' className="navbar-award" >
					<div className="service">
						<h3>Brijrama Hotel Awards</h3>
					</div>
					</a>
				</div>
				<div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
				<a href='#indus' className="navbar-award">
					<div className="service">
						<h3>indus intex awards</h3>
					</div>
					</a>
				</div>
			</div>
		</div>       

        <br/>
        <br/>
        {/* <div class="accordion" id="accordionExample">
            <div class="card-header" id="headingOne">
            <h2 class="mb-0">
                <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                Collapsible Group Item #1
                </button>
            </h2>
            </div>

            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
            <div class="card-body">
                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
            </div>
            </div>

            <div class="card-header" id="headingTwo">
            <h2 class="mb-0">
                <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                Collapsible Group Item #2
                </button>
            </h2>
            </div>
            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
            <div class="card-body">
                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
            </div>
            </div>
        
        </div> */}


             <div className="row">
                 <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                     <h2 className="global_title" id="clarks"><span>CLARKS EXOTICA AWARDS</span></h2>

                     <h5>Peak of Success Award</h5>
                     <p>Recognition of Inspirational Company, by Confederation of Business (WorldCob) at the Bizz 2017 Peak of Success Awards.</p>
                     
                     <h5>Certificate of Excellence</h5>
                     <p>Certificate of Excellence by Trip Advisor, Massachusetts, USA for 2 consecutive years (2014 & 2015). </p>

                     <h5>Bizz Award </h5>
                     <p>For World Business Leaders by World Confederation of Business (WORLD COB), at the Bizz Awards held in Muscat, Oman in October 2015.</p>

                     <h5>International Service Excellence Award </h5>
                     <p>Customer Charter by Customer Service Institute of  Australia (CSIA) – The premier institute of Australian Government.</p>

                     <h5>Recognition of Inspirational Company</h5>
                     <p>The Recognition of Inspirational Company with all its rights & privileges, conferred on Clarks Exotica Convention Resort & Spa by World Confederation of Businesses (Worldcob) at the Bizz 2017 Peak of Success Awards Held In Dubai, United Arab Emirates in November 2017.</p>
                     
                     <h5>Asian Lifestyle Tourism Awards</h5>
                     <p>Asia Leading Resorts Inc. conferred Clarks Exotica Convention Resort & Spa with 2 titles, namely “Best Wedding Destination in India for the year 2017-2018” & “Outstanding Spa Resort Destination in India for the year 2017-2018” at the Asian Lifestyle Tourism Awards Held at the Marina Bay Sands, Singapore in August 2017.</p>

                     <h5>International 5-star Hotel Award</h5>
                     <p>The International 5-star Hotel Award in the Category Of Resort Hotel in India for the Asia Pacific Region was conferred, two years in a row, on Clarks Exotica Convention Resort & Spaby the International Five Star Hotel in association with Rolls Royce, at the International Hotel Awards & Business Summit, held in London, United Kingdom in January2015 and once again in January 2016.</p>

                     <h5>Industry Excellence Awards</h5>
                     <p>Best Resort For Meetings Incentives Conferences & Exhibitions (Mice), awarded to Clarks Exotica Convention Resort & Spa By Cmo Asia at the Asia Hotel Industry Excellence Awards Held in Singapore in August 2015.</p>

                     <h5>International Arch of Europe Award</h5>
                     <p>International Arch Of Europe Award in Platinum Category 2014 for commitment to quality and Excellence Awarded to Clarks Exotica Convention Resort & Spa By Business Initiative Directions (Bid), Spain during the International B.I.D Quality Convention held in Frankfurt, Germany in April 2014.</p>

                     <h5>Diamond Eye Award</h5>
                     <p>The Diamond Eye Award for Quality Commitment & Excellence conferred on Clarks Exotica Convention Resort & Spa By Otherways Management Association Club (Omac), France at a ceremony held in Berlin, Germany in March 2014.</p>
                     
                     <h5>European Award</h5>
                     <p>European Award for Best Practices 2013 Awarded to Clarks Exotica Convention Resort & Spa by European Society for Quality Research (Esqr) Switzerland, at a Ceremony held in Vienna, Austria in December 2013.</p>

                     <h5>International Quality Summit Gold Category Award</h5>
                     <p>International Quality Summit Gold Category Award conferred on Clarks Exotica Convention Resort & Spa from Business Initiative Directions (Bid) Spain, held in a ceremony at New York, Usa in May 2013.</p>

                     <h5>Glamorous India Travel Awards</h5>
                     <p>Clarks Exotica Convention Resort & Spa conferred with the “Best Mice Destination” in South India at the Glamorous India Travel Awards 2018 – South by Ddp Publications on 14th May 2018 in Bengaluru.</p>

                     <h5>India Mice Awards</h5>
                     <p>Clarks Exotica Convention Resort & Spa Awarded with the Best Convention & Exhibition Centre at the India Mice Awards held in New Delhi in November 2017.</p>

                     <h5>Kaustubh Award</h5>
                     <p>The Kaustubh Award of Best New Convention Centre-south India awarded to Ocean Hall, Clarks Exotica Convention Resort & Spa by Travtour Mice Guide in February 2017.</p>
                     
                     <h5>Luxury Hotel Award</h5>
                     <p>Luxury Hotel Award 2016 presented to Clarks Exotica Convention Resort & Spa by Yatra.Com. </p>

                     <h5>South India Travel Awards</h5>
                     <p>Best Luxury Wedding & Mice Resort 2015 presented to Clarks Exotica Convention Resort & Spa from the Indian Ministry of Tourism at the South India Travel Awards, held at Hyderabad, India in April 2015</p>

                     <h5>Awesome Food Award</h5>
                     <p>Awesome Food Award conferred on Clarks Exotica Convention Resort & Spa by India says yes in New Delhi in 2015.</p>

                     <h5>Today’s Travellers Award</h5>
                     <p>Today’s Travellers Award for the Best 5-star Resort in Bangalore conferred on Clarks Exotica Convention Resort & Spa by Incredible India Supported by the Ministry of Tourism, Govt. Of India. held in New Delhi in September 2014.</p>
                     
                     <h5>Award Of Ultimate Service</h5>
                     <p>The Award of Ultimate Service in Hospitality presented to Clarks Exotica Convention Resort & Spa from the Indian Ministry of Tourism held at the South India Travel Awards in Hyderabad in April 2014.</p>

                     <h5>6th Golden Star Awards</h5>
                     <p>Stars of Industries, Corporates on Social Responsibility (Csr) qualified Clarks Exotica Convention Resort & Spa as the Resort Hotel of the year at the 6th Golden Star Awards in Mumbai in February 2014.</p>

                     <h2 id="brijrama" className="global_title"><span>Brijrama Hotel </span></h2>

                     <h5>Gold Circle Award Winner</h5>
                     <p>Gold Circle Award Winner, by Agoda - 2018 & 2019.</p>

                     <h5>Recognition Bespoke Hospitality Product</h5>
                     <p>India International Travel Mart, in Recognition Bespoke Hospitality Product, Iitm Kolkata 2019.</p>
                     
                     <h5>Top Rated Hotel (4.7/5)</h5>
                     <p>Top Rated Hotel (4.7/5) 2018-2019 by Make My Trip.</p>
                     
                     <h5>Traveller Review Awards</h5>
                     <p>Traveller Review Awards 2020 by Booking.Com (9.1/10).</p>
                     
                     <h5>Traveler’s Choice</h5>
                     <p>Traveler’s Choice 2020 by Tripadvisor.</p>
                     
                     <h5>Loved by Guests Award</h5>
                     <p>Loved by Guests Award 2020 by Hotels.Com (9.6/10)</p>

                     <h2 id="indus" className="global_title"><span>INDUS INTEX AWARDS</span></h2>

                     <h5>CERTIFICATE OF ELIGIBILITY</h5>
                     <div><img  rel="noreferrer" src="../assets/images/certificate/FairtradeCertificate_page-0001.jpg" alt=""/></div>

                     <h5>PLATINUM CERTIFICATE OF COMPLIANCE</h5>
                     <div><img  rel="noreferrer" src="../assets/images/certificate/WrapCertificate-2019-page-0.jpg" alt=""/></div>

                     <h5>CERTIFICATE OF COMPLIANCE</h5> 
                     <div><img  rel="noreferrer" src="../assets/images/certificate/GRS-IndusIntex-19-T-GRS_page-0001.jpg" alt=""/></div>

                     <h5>CERTIFICATE OF COMPLIANCE</h5> 
                     <div><img  rel="noreferrer" src="../assets/images/certificate/GOTS-Indus Intex-19-T-GOTS_page-0001.jpg" alt=""/></div>
                 </div>
                
             </div>
         </div>
     </div>
     {/* <!-- Team Sec --> */}



 </section>
            </div>
            )
        }
    }
    export default Awards